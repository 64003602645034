<template>
  <div id="admin-panel">
    <div v-if="user.data.admin">
      <Header active_tab="revision"></Header>
      <div class="row">
        <div class="col">
          <SmallCard
            address="/create-revision/"
            link="gridicons:create"
            title="Create Revision"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <SmallCard
            address="/previous-revision/"
            link="mdi:page-previous-outline"
            title="Previous Revisions"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <Header active_tab="revision"></Header>
      <div class="row d-flex justify-content-center">
        <div class="col" v-for="(item, index) in categories" :key="index">
          <SmallCard
            :address="'/all-revisions/' + item.name"
            :link="item.img"
            :title="item.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import store from "@/store";
import db from "../../../firebase.js";
const SmallCard = defineAsyncComponent(() =>
  import("@/components/SmallCard.vue")
);
const Header = defineAsyncComponent(() => import("@/components/Header"));

export default {
  async mounted() {
    store.state.loading = false;
    const docs = await db.collection("revision_categories").get();
    this.categories = docs.docs.map((doc) => doc.data());
    this.categories.push({
      name: "All revisions",
      img: "random url",
    });
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    SmallCard,
    Header,
  },
  data() {
    return {
      categories: "",
    };
  },
};
</script>
<style scoped>
#admin-panel {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%, red);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
</style>
